.ant-collapse-header-text {
  font-weight: 600;
}

.img-width {
  max-width: 100%;
}

.btn-container {
  display: flex;
  justify-content: flex-end;
  height: 100%;
  padding: 1.5rem;
}

.collapse-icon {
  stroke-width: 25;
  stroke: #2da01d;
  color: #2da01d;
}
