.blurring-overlay {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  z-index: 1000;
}

.blurring-content {
  max-width: 400px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content-text {
  color: #101828;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
}
