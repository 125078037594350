.documentation {
  padding: 12px 24px;
  overflow: auto;
  width: 100%;
}

.documentation p {
  margin: 0 0 20px; /* Adds space between paragraphs */
}

/* Headings */
.documentation h1,
.documentation h2,
.documentation h3,
.documentation h4,
.documentation h5,
.documentation h6 {
  margin: 20px 0 10px; /* Adds space above and below headings */
  font-weight: bold;
  color: #333;
}

.documentation h1 {
  font-size: 2.5rem;
}

.documentation h2 {
  font-size: 2rem;
}

.documentation h3 {
  font-size: 1.5rem;
}

.documentation h4 {
  font-size: 1.2rem;
}

.documentation h5 {
  font-size: 1rem;
}

.documentation h6 {
  font-size: 0.8rem;
}

/* Links */
.documentation a {
  color: #007bff;
  text-decoration: none;
}

.documentation a:hover {
  text-decoration: underline;
}

/* Unordered and ordered lists */
.documentation ul,
.documentation ol {
  margin: 0 0 20px 20px; /* Adds space below lists */
}

.documentation li {
  margin-bottom: 5px; /* Adds space between list items */
}

/* Images */
.documentation img {
  max-width: 100%;
  height: auto;
  display: block; /* Ensures images do not extend beyond their containers */
}

/* Preformatted text */
.documentation pre {
  background-color: #f4f4f4;
  padding: 10px;
  border-radius: 5px;
  overflow-x: auto;
}

/* Code */
.documentation code {
  font-family: Consolas, Monaco, 'Andale Mono', monospace;
  background-color: #f8f8f8;
  padding: 2px 4px;
  border-radius: 3px;
}

/* Embedded content */
.documentation iframe {
  width: 100%;
}

/* Sections */
.documentation section {
  margin-bottom: 20px;
}

/* Horizontal rule */
.documentation hr {
  margin: 20px 0;
  border: 1px solid #ccc;
}

/* Blockquote */
.documentation blockquote {
  margin: 20px 0;
  padding: 10px 20px;
  background-color: #f9f9f9;
  border-left: 5px solid #ccc;
}
