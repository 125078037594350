.registrationPage {
  min-height: 100vh;
  background: #f2f4f7;
  --primary-color: #2da01d;
}

.registrationPage__steps {
  margin: 5px auto 0 auto;
  max-width: 360px;
  width: 100%;
}

.registrationPage__img {
  height: 30px;
  width: auto;
}

.registrationPage__content {
  margin: 40px auto 0 auto;
  max-width: 360px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.registrationPage .ant5-radio-group {
  width: 100%;
}

.registrationPage .ant5-radio-wrapper span {
  color: #475467;
}

.registrationPage .ant5-radio-button-wrapper {
  height: auto;
  line-height: normal;
  padding: 2px 6px;
  margin: 2px;
  color: #6d84a3;
}

.registrationPage .ant5-radio-button-wrapper:hover {
  color: var(--primary-color);
}

.registrationPage .an5t-radio:hover .ant5-radio-inner {
  border-color: var(--primary-color);
}

.registrationPage
  .ant5-radio-button-wrapper-checked:not(
    [class*=' ant-radio-button-wrapper-disabled']
  ).ant5-radio-button-wrapper:first-child {
  border-color: var(--primary-color);
}

.registrationPage
  .ant5-radio-group-solid
  .ant5-radio-button-wrapper-checked:not(.ant5-radio-button-wrapper-disabled):hover {
  color: var(--primary-color);
  background: #e5fff3;
  border-color: var(--primary-color);
}

.registrationPage .ant5-radio-group-solid .ant5-radio-button-wrapper-checked:not(.ant5-radio-button-wrapper-disabled) {
  color: #fff;
  background: var(--primary-color);
  border-color: var(--primary-color);
}

.registrationPage .ant5-space {
  width: 100%;
}

.registrationPage .ant5-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.registrationPage .ant5-btn-link {
  color: #6d84a3;
  margin-top: 20px;
}

.registrationPage .ant5-btn-primary {
  width: 100%;
}

.registrationPage .ant5-btn-link:not(:disabled):not(.ant5-btn-disabled):hover {
  color: var(--primary-color);
  margin-top: 20px;
}

.registrationPage .ant5-steps-item-disabled {
  cursor: auto;
}

.registrationPage
  .ant5-steps-item
  > .ant5-steps-item-container
  > .ant5-steps-item-content
  > .ant5-steps-item-title::after {
  background: transparent !important;
}

.registrationPage .ant5-steps-item-custom > .ant5-steps-item-container > .ant5-steps-item-icon > .ant5-steps-icon {
  font-size: 60px;
}

.registrationPage .ant5-steps-item-custom.ant5-steps-item-process .ant5-steps-item-icon > .ant5-steps-icon {
  color: var(--primary-color);
}

.registrationPage .ant5-steps-item-custom.ant5-steps-item-wait .ant5-steps-item-icon > .ant5-steps-icon {
  color: #e4ebf5;
}

.registrationPage .ant5-steps-item-custom.ant5-steps-item-finish .ant5-steps-item-icon > .ant5-steps-icon {
  color: var(--primary-color);
}

.registrationPage .ant5-steps-horizontal:not(.ant5-steps-label-vertical) .ant5-steps-item {
  padding-left: 0px;
}

.registrationPage .ant5-checkbox-group span {
  color: #475467;
}

.registrationPage .ant5-checkbox-group {
  display: flex;
  flex-direction: column;
}

.registrationPage
  .ant5-steps
  .ant5-steps-item-finish
  > .ant5-steps-item-container
  > .ant5-steps-item-content
  > .ant5-steps-item-title {
  display: none;
}

.registrationPage .ant5-radio-wrapper {
  width: 100%;
  background: #ffffff;
  padding: 6px;
  border: 1px solid #e3ebf6;
}

.registrationPage .ant5-radio-wrapper:hover {
  border: 1px solid var(--primary-color);
}

.registrationPage .ant5-radio-wrapper-checked {
  border: 1px solid var(--primary-color);
  background: #e5fff3;
}
