.plans-grid {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr;
  @media (min-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: 1350px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (min-width: 1650px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.plans-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2); /* Semi-transparent black background */
  z-index: 1000; /* Set a high z-index to ensure the overlay is above other content */
  display: flex;
  align-items: center;
  justify-content: center;
}

.plans-overlay-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 350px;
  font-size: 16px;
}

@media only screen and (max-width: 1600px) {
  #divmainItemTable .table-responsive::after,
  #divsitemapSettings .table-responsive::after,
  #divcachedPages .table-responsive::after {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 15px;
    pointer-events: none;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), #fff 85%);
    width: 5%;
  }
}

.wrap-table-content {
  overflow-wrap: anywhere;
}

svg {
  vertical-align: baseline;
}

.ant5-steps-finish-icon {
  vertical-align: 0em;
}

.ant5-modal-content {
  border-radius: 5px;
}

.ant5-table {
  font-size: 13px;
}
