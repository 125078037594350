.recache-interval-setting-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.recache-interval-setting-header {
  font-size: 14px;
  font-weight: 600;
  text-align: left;
}

.recache-interval-setting-element-slider {
  flex-grow: 1;
}

.recache-interval-setting-days {
  /* To contain two digit values: 52 + 2*5 padding*/
  width: 62px;
  text-align: right;
}
