.interval-card-header {
  height: 3.5em;
}

@media (min-width: 992px) {
  .interval-card-header {
    height: 4.5em;
  }
}

@media (min-width: 1200px) {
  .interval-card-header {
    height: 3.5em;
  }
}
