.billing-dues-card-warn {
  background-color: rgba(255, 255, 0, 0.1);
}

.billing-dues-card-danger {
  background-color: rgba(255, 0, 0, 0.1);
}

.billing-dues-text-warn {
  color: rgb(204, 102, 0);
}

.billing-dues-text-danger {
  color: red;
}

.billing-dues-button-warn {
  color: rgb(204, 102, 0);
  border: 2px solid rgb(204, 102, 0);
}

.billing-dues-button-danger {
  color: red;
  border: 2px solid red;
}
